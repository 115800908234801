.container-login100{
    /* background-image:  url('../../assets/Images/LoginImage.jpg') !important; */
}
.whitelabel-background{
    background-image:  url('./images/back1.svg') !important ;
}
.bottom-span{
    color: #007bff !important;
    cursor: pointer;
}
.bottom-span:hover {
    text-decoration: underline !important;
}

.backgroundDark{
    background-color: #333333 !important;
}