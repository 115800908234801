/* -------------- Auth Layout ---------- */
.auth-container {
  height: 100vh;
  background-color: #fdf7f3;
  display: flex;
}

.left-section {
  background-image: linear-gradient(
      179.97deg,
      rgba(128, 47, 0, 0.8) 0.03%,
      rgba(29, 11, 0, 0.931745) 74.74%,
      #000000 93.61%
    ),
    url("../../../assets/Images/AuthBackground.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
  width: 100%;
}

.logo {
  width: 120px;
  height: 32px;
  position: absolute;
  top: 12%;
  left: 12%;
}

.text-box {
  position: absolute;
  bottom: 10%;
  text-align: left;
  padding: 0 10%;
}

.border-bottom {
  border-bottom: 1px solid;
  border-image-source: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.02) 0%,
    rgba(255, 255, 255, 0.32) 50%,
    rgba(255, 255, 255, 0.02) 100%
  );
  border-image-slice: 1;
  width: 100%;
  margin-top: 10px;
}

.benefits-list {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.benefit-item {
  display: flex;
  align-items: center;
  color: white;
  gap: 0.5rem;
}

.right-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

/* ------------ Pages ------------- */
.main-container {
  width: 100%;
  padding: 0 10%;
}

@media (max-width: 900px) {
  .left-section {
    display: none;
  }
  .main-container {
    max-width: 600px;
  }
}

.otp-verification-left {
  background-image: url("../../../assets/Images/OtpVerification.png");
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
  width: 70%;
}
.otp-verification-right {
  box-shadow: none;
}
.otp-verification {
  background-color: #fff;
}
.otp-verification-container {
  padding: 0 20%;
}
@media (max-width: 1200px) {
  .otp-verification-container {
    padding: 0 10%;
  }
}
