.main-div {
  /* background: url("../../../assets/Images/loginBg1.svg") no-repeat !important; */
  height: 100vh;
  width: 40vw;
}


.main-div-2 {
  display: flex;
  margin-top: -60px;
  margin-left: 15vh;
}

.login-div {
  width: 60vw;
  margin: auto;
  justify-content: center;
  height: 100vh;
}

.outer-div {
  max-width: 65vh;
  width: auto;
  margin: auto;
  margin-top: 20vh !important;

}

.wrap-login100-form-btn {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 5px !important;
  overflow: hidden;
  margin: 0 auto;

  box-shadow: 0 5px 30px 0px rgba(3, 216, 222, 0.2);
  -moz-box-shadow: 0 5px 30px 0px rgba(3, 216, 222, 0.2);
  -webkit-box-shadow: 0 5px 30px 0px rgba(3, 216, 222, 0.2);
  -o-box-shadow: 0 5px 30px 0px rgba(3, 216, 222, 0.2);
  -ms-box-shadow: 0 5px 30px 0px rgba(3, 216, 222, 0.2);
}

.login100-form-bgbtn {
  position: absolute;
  z-index: -1;
  width: 300%;
  height: 100%;
  background: #ff5e00 !important;
  top: 0;
  left: -100%;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login-form-btn {
  font-family: Poppins-Medium;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 40px;
  border-radius: 3px !important;
}

.login-heading {
  margin-bottom: 0;
  font-weight: 700;
}

.login-sub-heading {
  margin-left: 1px;
  margin-bottom: 15% !important;
}

.google-login {
  width: 100%;
  margin-top: 10px;
  border-radius: 5px !important;
  justify-content: center;
}

.forgot-password {
  color: #ff9466 !important;
}

.sales-line {
  color: white;
  align-self: center;
  margin-left: -20px;
}

/* Responsive styles */

@media only screen and (max-width: 768px) {
  .main-div {
    height: 100vh;
  }

  .main-div-1 {
    margin-top: -30px;
  }
  .login-div {
    width: 80vw;
    margin-left: 10vw;
  }
}

@media only screen and (max-width: 576px) {
  .main-div {
    height: 90vh;
  }
  .main-div-2 {
    margin-left: 10vh;
  }
}



.vodex-img {
  margin-left: 15px;
  margin-top: 15px;
}

.vodex-logo {
  /* margin-left: 15px; */
  margin-top: 15px;
  margin-left: 18px;
  width: 50px;
  height: 50px;
  z-index: 1000;
}
.project-input {

  max-width: 350px !important;
}

.custom-save-button {
  width: 350px !important;
  height: 44px !important;
  gap: 16px;
  border-radius: 6px !important;
  background-color: var(--vodex-orange);
  color: #fff;
  margin-top: 30px;
  margin-bottom: 20px;
}

.custom-save-button-1 {
  width: 200px !important;
  height: 44px !important;
  gap: 16px;
  border-radius: 6px !important;
  background-color: var(--vodex-orange);
  color: #fff;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-right: 4px;
}
.agent-left {
  /* background: url("../../../assets/Images/leftPanel.svg") no-repeat !important; */
  background-size: 15vh !important;

  z-index: 0;
}
.agent-left-whitelabel {
  /* background: url("../../../assets/Images/blugBg.svg") no-repeat !important; */
  background-size: 15vh !important;
  z-index: 0;
}
.white-label-colour{
  background-color: #3A416F;
}

.steps {
  display: flex;
}
.steps-whitelabel{
  display: flex
}
.steps span {
  margin: 2px;
  border-radius: 20px;
  margin-bottom: 20px;
  width: 30px;
  height: 10px;
  background-color: var(--button-orange)
}
.steps-whitelabel span {
  margin: 2px;
  border-radius: 20px;
  margin-bottom: 20px;
  width: 30px;
  height: 10px;
  background-color: #8c96db 
}
.selected-spanW {
  margin: 2px;
  border-radius: 20px !important;
  margin-bottom: 20px;
  width: 50px !important;
  height: 10px !important;
  background-color: var(--white-label-color) !important
}
.selected-span {
  margin: 2px;
  border-radius: 20px !important;
  margin-bottom: 20px;
  width: 50px !important;
  height: 10px !important;
  background-color: var(--vodex-orange) !important
}

.chat-input-section {
  border: 1px solid var(--vodex-orange) ;
  padding: 3px;
  border-radius: 6px;
  width: 500px;
  padding-bottom: 0;
  padding-top: 2px;
  position: relative;
}

.chat-input {
  width: 440px;
  font-size: 12px !important;
}








.chat-input:focus {
  outline: none;
  border: none;
}

.chat-input:hover {
  outline: none;
  border: none;
}

.call-received-div {
  padding: 15px;
}

.chat-feedback {
  background: #fff !important;
  box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01),
    0px 105px 63px rgba(0, 0, 0, 0.05), 0px 47px 47px rgba(0, 0, 0, 0.09),
    0px 12px 26px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  position: relative;
  border-radius: 18px;
  min-width: 450px !important;
}

.modal-content {
  border-radius: 20px;
}

.color-white {
  color: #fff !important;
}

.text-align-center {
  text-align: center !important;
}

.green-border {
  width: 200px !important;
  height: 44px !important;
  gap: 16px;
  border-radius: 6px !important;
  color: #000;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-right: 4px;
  border: 1px solid #15803D;
}

.green-border:hover {
  background-color: #15803D;
  color: #fff;
}

.red-border {
  width: 200px !important;
  height: 44px !important;
  gap: 16px;
  border-radius: 6px !important;
  color: #000;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-right: 4px;
  border: 1px solid #B91C1C;
}

.red-border:hover {
  background-color: #B91C1C;
  color: #fff;
}


.text-wrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.text-feedback {
  color: #374151 !important;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-bottom: 0;
}

.text-secondary {
  color: #cccccc;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin-bottom: 0;
}

.rating-stars-container {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.star-label svg {
  fill: rgb(210, 210, 210);
  width: 24px;
  height: auto;
  cursor: pointer;

}

.rating-stars-container input {
  appearance: unset;

}

.rating-stars-container input:hover~.star-label svg {
  fill: #ffd242;
}

.rating-stars-container input:checked~.star-label svg {
  fill: rgb(255, 102, 47);
  animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-fwd-center {
  0% {
    transform: scale(1.6);
  }

  100% {
    transform: scale(1);
  }
}

@media only screen and (max-width: 660px) {

  .project-image {
    margin-top: -63px !important;
  }
}

@media only screen and (max-width: 660px) {
  .project-image {
    margin-top: -63px !important;
  }

  .agent-left {
    background: none !important;
  }

  .mobile-view {
    display: none;
  }
  .agent-left-whitelabel{
background: none !important;  }

  .ai-chat {
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 660px) {

  .web-view {
    display: none;
  }
}

.chat-input-section-max {
  height: auto;
  border: 1px solid var(--vodex-orange) ;
  border-radius: 6px;
  width: 900px;
  margin: auto;
  margin-top: -10px;
  padding-bottom: 0;
  padding-top: 2px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.input-mini {
  width: calc(100% - 48px);
  min-height: 40px;
  resize: none;
  overflow-y: hidden;
  font-size: 0.8rem;
  border-radius: 4px;
  padding: 10px;
  
}

.input-mini:focus {
  outline: none;
  border-color: #007bff;
}

.input-mini::placeholder {
  color: #999;
}

.input-max {
  width: calc(100% - 48px);
  min-height: 52px;
  max-height: 120px;
  resize: none;
  overflow-y: hidden;
  font-size: 0.9rem !important;
  border-radius: 4px;
  padding: 10px;
  overflow-y: auto;
}


.input-max:focus {
  outline: none;
  border-color: #007bff;
}

.input-max::placeholder {
  color: #999;
}

.send-button {
  position: absolute;
  bottom: 8px;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
}

.send-button:hover {
  transform: scale(1.06);

}


.send-button-max {
  position: absolute;
  bottom: 8.5px;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  right: 5px;
}

.send-button-max:hover {
  transform: scale(1.06);

}




.container-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-top: -60px;
}

.responsive-image {
  max-width: 70%;
  max-height: 70%;
  width: auto;
  height: auto;
}


.project-card {
  width: 100vw;
  height: 100vh;
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 0 !important;
 
}

@media only screen and (min-width: 1400px) {
 
  .container-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .responsive-image {
    max-width: 70%;
    max-height: 70%;
    width: auto;
    height: auto;
  }
  /* .main-div {
    background: url("../../../assets/Images/loginBg1.svg") no-repeat !important;
    
    
  } */
 
}

.quickstart-footer {
  background-image: url(../../../assets/Images/FooterImage.svg);
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
  width: 100%;
  min-height: calc(100vh - 60px);
  height: 100%;
}

/* Prompt Section */

.prompt-section {
  min-height: 65px;
  max-height: 140px;
  display: flex;
  flex-direction: column;
}

.prompt-area {
  overflow-y: auto;
}

 
.prompt-area::-webkit-scrollbar {
  width: 6px ; 
  background: transparent; 
}

.prompt-area::-webkit-scrollbar-thumb {
  background-color: #6c757d ; 
  border-radius: 10px; 
}

.prompt-area::-webkit-scrollbar-track {
  background-color: transparent; 
}








