.transcript-container {
  max-width: 800px !important;
  margin: 8px auto;
  border-radius: 8px;
  box-shadow: 24px;
  background-color: white;
}
.transcript-header {
  padding: 24px 24px 16px 24px;
  border-radius: 8px;
  background-color: #f1f4fa;
}
.message-scroll-container {
  background-color: white;
  height: 300px !important;
  border-radius: 8px;
  padding-top: 16px;
}
.transcript-select {
  background-color: #fff;
  font-size: 14px !important;
  font-weight: 400 !important;
  border-color: #ccd2de;
  border-radius: 8px;
  width: 190px;
  padding: 4px 10px;
  height: 40px;
}

.circular-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.duration-container {
  height: 40px;
  background: #f1f4fa;
  border-radius: 100px;
  padding: 0 4px;
}
