.floating-widget {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ff5e00;
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.floating-widget i {
  font-size: 25px;
  cursor: pointer;
  color: black;
}

.floating-widget-youtube {
  position: fixed;
  bottom: 20px;
  right: 90px;
  background-color: #fff;
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.floating-widget-youtube i {
  font-size: 30px;
  cursor: pointer;
  color: red;
}
.card-transform:hover {
  transform: scale(1.02);
  box-shadow: 0px 0px 1px 1px #ff5e00 !important;
}

.card-error {
  border: 3px solid red !important;
  position: relative;
}

.card-error-cont {
  height: 50px;
  width: 150px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid red;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 8px;
}

.card-error-text {
  color: white;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.5px;
  cursor: default;
}
/* Date Picker */

.custom-date-input {
  width: 100%;
  padding: 6px 15px;
  border-radius: 8px;
  font-size: 13px;
  color: #555555;
  outline: none;
}
.calendar-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #555555;
  pointer-events: none;
}

.donut-container {
  border: 1px solid #d1d9ef;
  border-radius: 8px;
  padding: 24px 16px;
  height: 100%;
}
.donut-inner-conatiner {
  width: 240px;
  height: 240px;
  margin: auto;
}
.text-container {
  background-color: #f8fafe;
  padding: 8px 12px;
  border-radius: 8px;
}
