.placeholder-ip {
  position: relative;
  display: inline-block;
}
.input-container {
  position: relative;
}
.custom-placeholder {
  position: absolute;
  top: 48%;
  left: 14px;
  font-size: 13px;
  transform: translateY(-50%);
  color: #555555;
  pointer-events: none;
  z-index: 99;
}
.common-ip {
  width: 100%;
  border: 1px solid #ccd2de;
  border-radius: 8px;
  padding: 10px 13px;
  outline: none;
  font-size: 14px;
}
.common-ip:focus {
  outline: 1px solid #ccd2de;
}
.common-ip::placeholder {
  color: #555555;
  font-size: 13px;
  font-weight: 400;
}

.new-date-picker {
  width: 100%;
  border: 1px solid #ccd2de;
  border-radius: 8px;
  padding: 4px 3px;
}
.custom-date-input {
  width: 100%;
  padding: 7px 45px 7px 12px;
  border-radius: 8px;
  font-size: 13px;
  color: #555555;
  outline: none;
}
.react-datepicker-popper {
  z-index: 9999;
}
.datepicker-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #555555;
  pointer-events: none;
}
.calendar-icon {
  font-size: 14px;
}
.check-label {
  margin: 0;
  padding: 0;
  display: inline;
}
.custm-ip {
  font-size: 13px;
}
.custm-ip::placeholder {
  font-size: 13px;
  font-weight: 400;
  color: #9e9fa1;
}

/* Fonts */
.font-12-grey {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #181818 !important;
}
.font-12-500-lightgrey {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #999999 !important;
}
.font-12-600-grey {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #181818 !important;
}
.font-12-lightgrey {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #555555 !important;
}
.font-12-400-lightgrey {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #555555 !important;
}
.font-12-mediumgrey {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #333333 !important;
}
.font-13-400-grey {
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #181818 !important;
}
.font-13-lightgrey {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #555555 !important;
}
.font-13-lightgrey-2 {
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #666666 !important;
}
.font-13-darkgrey {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #333333 !important;
}
.font-14-grey {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #181818 !important;
}
.font-14-400-grey {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #666666 !important;
}
.font-14-400-dark-grey {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #181818 !important;
}
.font-14-lightgrey {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #555555 !important;
}
.font-14-400-lightgrey {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #555555 !important;
}
.font-400-14-lightgrey {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: rgba(102, 102, 102, 1) !important;
}
.font-14-darkGrey {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: rgba(24, 24, 24, 1) !important;
}
.font-14-600-grey {
  font-size: 14 !important;
  font-weight: 600 !important;
  color: #181818 !important;
}
.font-14-grey2 {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: rgba(101, 110, 127, 1) !important;
}
.font-14-grey3 {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #333333 !important;
}
.font-16-lightgrey {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #555555 !important;
}
.font-16-700-grey {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #181818 !important;
}
.font-16-lightgrey-2 {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: rgba(102, 102, 102, 1) !important;
}
.font-16-grey {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #181818 !important;
}
.font-16-600-grey {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #181818 !important;
}
.font-18-grey {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #181818 !important;
}
.font-18-darkGrey {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: rgba(24, 24, 24, 1) !important;
}
.font-18-700-grey {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #1c203e !important;
}
.font-20-grey {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #181818 !important;
}
.font-20-500-lightgrey {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #555555 !important;
}
.font-20-700-lightgrey {
  color: #55555599 !important;
  font-weight: 700 !important;
  font-size: 20px !important;
}
.font-20-600-lightgrey {
  font-weight: 600 !important;
  font-size: 20px !important;
  color: #1c203e !important;
}
.font-28-700-grey {
  color: #1a1d23 !important;
  font-weight: 700 !important;
  font-size: 28px !important;
}
.font-alert-text {
  color: #0075ad !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}
.font-600-red {
  color: #800000 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

/* Flex Layout */
.flexOnly {
  display: flex !important;
}
.flex-row {
  display: flex !important;
  flex-direction: row !important;
}
.flex-column {
  display: flex !important;
  flex-direction: column !important;
}
.flex-align-center {
  display: flex !important;
  align-items: center !important;
}
.flex-row-center {
  display: flex !important;
  justify-content: center !important;
}
.flex-xy-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.flex-between {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.flex-between-only {
  display: flex !important;
  justify-content: space-between !important;
}
.flex-evenly {
  display: flex !important;
  align-items: center !important;
  justify-content: space-evenly !important;
}
.flex-around {
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
}
.flex-column-between {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}
.flex-column-center {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}
.flex-column-align-center {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}
.flex-end {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}
.cursor-pointer {
  cursor: pointer;
}
.chat-button {
  border-radius: 8px;
  background: rgba(255, 94, 0, 0.75);
  height: 40px;
  width: 40px;
  margin: 5px 10px;
}
.duration-filter-container {
  height: 40px;
  background: #f1f4fa;
  border-radius: 100px;
  padding: 0 5px;
}

.spinner-square {
  display: flex;
  flex-direction: row;
  width: 40px;
}

.spinner-square > .square {
  width: 6px;
  height: 20px;
  margin: auto;
  border-radius: 4px;
  position: relative;
}

.square-1 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s
    infinite;
}

.square-2 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 100ms
    infinite;
}

.square-3 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 200ms
    infinite;
}
.square-4 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 300ms
    infinite;
}
.square-5 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 400ms
    infinite;
}

@keyframes square-anim {
  0% {
    height: 40%;
  }
  20% {
    height: 40%;
  }
  40% {
    height: 100%;
  }
  80% {
    height: 40%;
  }
  100% {
    height: 40%;
  }
}
