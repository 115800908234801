.main-layout-container {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
  background-color: #181818;
}

.top-bar-container {
  height: 64px;
  z-index: 100;
  position: relative;
}

.inner-main-container {
  background-color: white;
  border-radius: 12px;
  height: 100%;
  overflow: hidden;
}

.email-container {
  width: 100%;
  background-color: #111111;
  border-radius: 8px;
  height: 40px;
  cursor: pointer;
  padding: 0 8px;
}

.email-edit-option {
  color: #555555 !important;
  gap: 8px;
  font-size: 13px !important;
  padding: 6px 0;
}

.no-activity {
  text-align: center;
  background-color: #f0f0f0;
  padding: 8px 4px;
  border-radius: 8px;
}

.main-searchbar-container {
  position: fixed;
  top: 80px;
  left: 100px;
  width: 350px;
  background-color: white;
  border-radius: 8px;
  z-index: 99;
  transition: opacity 0.3s ease, transform 0.3s ease;
  height: 380px;
  padding: 13px;
}
