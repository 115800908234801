.onboarding-header {
  background: #181818;
  height: 60px;
  padding: 10px 23px;
}

.scroll-container {
  height: 40vh !important;
  color: white;
  border-radius: 10px;
  margin-left: -22px;
  margin-top: 10px;
}

.big-area-container {
  overflow: hidden !important;
  margin-top: -34px !important;
  height: 108% !important;
}
.big-inner-container {
  background: white;
  width: 98%;
  height: 86%;
  padding: 20px;
  border-radius: 5px;
}
.big-close-icon {
  z-index: 1;
  margin-top: -17px;
  font-size: 20px;
  background-color: whitesmoke;
  float: right;
  padding: 10px;
}

.big-area-main {
  width: 750px !important;
  max-height: 100% !important;
  margin: 0 auto;
  padding-bottom: 20px;
}

.big-scroll-style {
  height: 70vh !important;
  color: white;
  border-radius: 10px;
  margin-top: 2px;
}

.big-textarea {
  border: 1px solid rgba(204, 210, 222, 1);
  border-radius: 8px;
}

.chat-button {
  border-radius: 8px;
  background: rgba(255, 94, 0, 0.75);
  height: 40px;
  width: 40px;
  margin: 5px 10px;
}
.message-box {
  padding: 8px;
  border-radius: 8px;
  border: 1px solid rgba(204, 210, 222, 1);
  background-color: #ffffff;
}
.small-area-container {
  border: 1px solid rgba(204, 210, 222, 1);
  border-radius: 8px;
  background-color: #ffffff;
}
